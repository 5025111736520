.pricing-table {
  text-align: center;
}

/* Each pricing-item*/
.pricing-item {
  border-radius: 3px;
  display: inline-block;
  width: 230px;
  height: auto;
  background: #fff;
  margin: 20px;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
  -webkit-transition: all .2s cubic-bezier(.3, .6, .2, 1.8);
  transition: all .2s cubic-bezier(.3, .6, .2, 1.8);
  &:hover {
      -webkit-transform: scale(1.04);
      -ms-transform: scale(1.04);
      transform: scale(1.04);
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
  }
  .pricing-title{
    width: 100%;
    color: white;
    display: block;
    position: relative;
    background: #00a65a;
    padding: 7px;
    font-weight: bold;
    font-size: 20px;
    //background: #FF4136;
    //background: #2ECC40;
  }
  &.pricing-featured .pricing-title{
    background: #FF4136;
  }
  &.pricing-2 .pricing-title{
    background: #0074D9;
  }
  &.pricing-free .pricing-title{
    background: #A9A9A9;
  }
}


/* pricing-value */
.pricing-value {
  width: 180px;
  height: 180px;
  padding-top: 46px;
  border-radius: 50%;
  color: #fff;
  font-size: 46px;
  font-weight: 300;
  margin: 10px auto;
}
.pricing-value .smallText {
  font-size: 14px;
}
.pricing-value .undertext {
  display: block;
  font-size: 16px;
}
.pricing-item .pricing-value {
  background: #00a65a;
  border: 2px solid #00a65a;
}
.pricing-item.pricing-featured .pricing-value{
  background: #FF4136;
  border: 2px solid #FF4136;
}
.pricing-item.pricing-2 .pricing-value{
  background: #0074D9;
  border: 2px solid #0074D9;
}
.pricing-item.pricing-free .pricing-value{
  background: #A9A9A9;
  border: 2px solid #A9A9A9;
}

/* List */
.pricing-item .pricing-features {
  margin: 10px 0;
  padding: 0;
  list-style: none;
  & li {
    display: block;
    width: 90%;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    font-weight: 400;
    border-bottom: 1px solid rgba( 0, 0, 0, 0.2);
    margin: 0 auto;
    .keywords {
      font-weight: bold;
    }
  }
}

.selected {
  z-index: 10;
  width: 180px;
  height: 32px;
  padding: 0 20px;
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 5px #888888;
  background: gold;
  border-top: 5px solid gold;
  border-bottom: 5px solid gold;
  //background: #palegoldenrod;
  transform: rotate(35deg);
  position: absolute;
  right: -47px;
  top: 17px;
}